@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif; /* Apply Fira Sans globally */
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.footer-container {
  margin: 0;
  background-color: #252525;
  width: 100%;
  color: #949494;
  font-family: 'Fira Sans', sans-serif; /* Ensure Times New Roman isn't applied */
}

.footer-columns {
  width: 100%; /* Set the container to full width */
  max-width: 1200px; /* Optional: set a max width for large screens */
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-column {
  width: 15%;
  margin: 20px 0;
}

.footer-column-logo {
  width: 20%;
}

.footer-logo {
  margin-bottom: 20px;
}
.footer-logo .f-logo{
  width: 40px;
}

.logo-icon {

  font-size: 10px;
  font-weight: bold;
}
.li-ft{
  text-decoration: none;
  color: #949494;
}


.footer-ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  cursor: pointer;
}

li:hover {
  color: #ccc;
}

.footer-title-explore,
.footer-title-business,
.footer-title-industries,
.footer-title-resources,
.footer-title-social {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.footer-bottom {
  margin-top: 40px;
  padding-bottom:30px;
  text-align: center;
  border-top: 1px solid #333;
  padding-top: 20px;
}

.footer-copyright {
  font-size: 0.9rem;
  color: #bbb;
}

.footer-link-terms,
.footer-link-privacy,
.footer-link-cookies {
  color: #f1f1f1;
  text-decoration: none;
}

.footer-link-terms:hover,
.footer-link-privacy:hover,
.footer-link-cookies:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-column {
    width: 45%;
  }

  .footer-column-logo {
    width: 100%;
    text-align: center;
  }

  .footer-columns {
    justify-content: center; /* Center columns for medium screens */
  }
}

@media (max-width: 480px) {
  .footer-column {
    width: 100%; /* Each column takes full width on small screens */
  }

  .footer-columns {
    text-align: center;
  }
}
