@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

:root {
  --primary-color: #8254FF;
  --primary-color-dark: #6632cc;
  --text-color: #252525;
  --secondary-text-color: #595959;
  --background-color: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.15);
}

.configurator-examples {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
}

.configurator-heading {
  font-family: 'Fira sans', sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #252525;
  margin-bottom: 30px;
}

.button-container {
  margin-bottom: 40px;
}

.insight-button {
  font-size: 1rem;
  padding: 10px 24px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  background-color: transparent;
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.insight-button:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

.card {
  background-color: var(--background-color);
  border-radius: 16px;
  box-shadow: 0px 6px 12px var(--shadow-color);
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #eaeaea;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.industry-text {
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;
  color: var(--secondary-text-color);
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.card-title {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 12px;
}

.card-description {
  font-family: 'Inter', sans-serif;
  font-size: 0.95rem;
  color: var(--secondary-text-color);
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
}

.view-demo-button {
  font-size: 0.9rem;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-demo-button:hover {
  background-color: var(--primary-color-dark);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .configurator-heading {
    font-size: 2.25rem;
  }
}

@media (max-width: 768px) {
  .configurator-heading {
    font-size: 1.75rem;
  }
  .insight-button, .view-demo-button {
    font-size: 0.85rem;
    padding: 10px 20px;
  }
}
