.variants-section {
  padding: 20px;
  /* background-color: #f8f8f8; */
  text-align: center;
}

.variants-header {
  margin-bottom: 20px;
}

.variants-title {
  font-size: 48px;
  font-weight: 500;
  color: #252525;
}

.variants-description {
  margin-top: 20px;
  font-size: 16px;
  color: #6c6c6c;
}

.variants-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.variant-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 280px;
  text-align: left;
}

.variant-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.variant-card-title {
  font-size: 20px;
  margin-top: 10px;
  color: #333;
}

.variant-card-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.variant-card-list-item {
  font-size: 14px;
  color: #555;
  padding: 4px 0;
}

.variant-card-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.variant-card-link:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .variants-title {
    font-size: 32px;
  }

 
}
