@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.bookdemo-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.title {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  margin-bottom: 20px;
}
.error-message{
  padding-left: 30px;
  text-align: left;
  font-family: "Inter",sans-serif;
  color: blue;
  font-size: 14px;
}

.success-message{
  /* padding-left: 30px;
  text-align: left; */
  font-family: "Inter",sans-serif;
  color: blue;
  font-size: 22px;

}
.form-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.contact-heading {
  font-family: "Fira Sans", sans-serif;
  font-size: 28px;
  margin-bottom: 20px;
}

.business-inquiry, .book-demo {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 400px;
  box-sizing: border-box;
  /* border: 2px solid red; */
}

input::placeholder {
  font-family: "Inter", sans-serif;
}

.inquiry-form .form-group {
  margin-bottom: 13px;
}

.inquiry-form input, 
.inquiry-form select,
.inquiry-form textarea {
  width: 85%; 
  height: 40px; 
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box; 
  font-family: "Fira Sans", sans-serif; 
  background-color: #efefef; 
}

.inquiry-form textarea {
  height: 80px; 
}

.inquiry-form input::placeholder,
.inquiry-form select::placeholder,
.inquiry-form textarea::placeholder {
  color: #595959;
  font-family: "Fira Sans", sans-serif; 
}

.inquiry-form input:focus,
.inquiry-form select:focus,
.inquiry-form textarea:focus {
  background-color:white; 
  border-color:#5400cc ;
  outline: none;
}

.submit-btn {
  color: #F7F7F7;
  width: 85%;
  background-color: #6100ff;
  height: 40px;
  font-family: 'Inter', sans-serif;
  border-radius: 5px;
  padding: 9px 16px;
  margin: 0px 15px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.schedule-call-btn {
  background-color: white;
  color: #6100ff;
  border: 2px solid #6100ff;
  height: 40px;
  font-family: 'Inter', sans-serif;
  border-radius: 5px;
  padding: 9px 16px;
  margin: 0px 15px;
  font-size: 18px;
  width: 85%;
  cursor: pointer;
}

.schedule-call-btn:hover, 
.submit-btn:hover {
  opacity: 0.8;
}

.book-demo .book-demo-head {
  font-family: "Fira Sans", sans-serif;
  font-size: 28px;
  margin-bottom: 20px;
}

.book-demo-para {
  color: #595959;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

.demo-image {
  margin: 40px 0;
  height: 350px;
  /* background-color: #f0f0f0; */
}
.bg-image{

  width: 100%;
  object-fit: cover;
  height: auto;
}

/* Responsive */
@media (max-width: 768px) {
  .form-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .error-message{
    
    font-size: 16px;
  }
  .success-message{
    font-size: 20px;
  }
  .business-inquiry,
  .book-demo {

    /* border: 2px solid red; */
    width: 100%;
    flex: none;
  }

  .submit-btn, 
  .schedule-call-btn {
    width: 85%;
  }

  .inquiry-form input, 
  .inquiry-form select,
  .inquiry-form textarea {
    height: 40px; /* Ensure all fields are consistent */
  }
  .inquiry-form textarea {
    height: 60px
  }
}

/* For screens up to 300px */




@media (max-width: 480px) {
  .bookdemo-container {
    width: 90%;
    padding: 10px;
  }

  .title {
    font-size: 32px;
  }
  .error-message{
    
    font-size: 14px;
  }
  .success-message{
    font-size: 18px;
  }
  .form-wrapper {
    gap: 15px;
  }

  .business-inquiry, 
  .book-demo {
    width: 100%;
    /* padding: 5px; */
    margin-bottom: 20px;
  }

  .inquiry-form input, 
  .inquiry-form select,
  .inquiry-form textarea {
    width: 100%; /* Full width on very small screens */
    padding: 8px;
  }

  .submit-btn, 
  .schedule-call-btn {
    width: 100%; /* Full width for buttons */
    margin: 10px 0;
  }

  .book-demo-para {
    font-size: 16px;
  }
  .error-message{
    padding-left: 5px;
  }

  .demo-image {
    width: 100%;
    height: 30%; /* Adjust the image height for smaller screens */
    /* margin: 10px 0; */
  }
}
