.cta-section {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.cta-heading {
  font-family: "Fira Sans", sans-serif;
  font-size: 58px;
  color: #252525;
  margin-bottom: 1rem;
  line-height: 1.2;
  width: 100%;
}

.cta-paragraph {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.cta-button-link {
  text-decoration: none;
}

.cta-button {
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #6d00ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #5400cc;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .cta-heading {
    font-size: 1.5em;
    width: 380px;
   
  }

  .cta-paragraph {
    font-size: 16px;
    width: 370px;
  }

  .cta-button {
    width: 130px;
    height: 50px;
     
    
     font-size: 0.9rem;
    padding: 10px 20px; 
  }
}

.threed-video-container {
  color: white;
  width: 80%;
  height: 650px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.threed-video {
  width: 100%;
  height: 650px;
  object-fit: cover;
  border-radius: inherit;
}

@media (max-width: 768px) {
  .threed-video-container {
    width: 90%;
    margin-top: 5%;
  }
}
@media (max-width: 480px) {
 
  .vimeo-2{
    /* border: 2px solid black; */
    height: 230px;
  }
}

.features-grid {
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 40px 20px;
  /* background-color: white; */
}

.feature-card {
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.feature-title {
  font-family: "Fira Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #252525;
}

.feature-description {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #595959;
  line-height: 1.5;
  max-width: 350px;
  margin: 0 auto;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
  .feature-card {
    margin-bottom: 20px;
  }
  .cta-heading {
    font-size: 30px;
  }

  .cta-paragraph {
    font-size: 16px;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
