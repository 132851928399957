/* Components/NotFound.css */
.not-found-container {
    text-align: center;
    padding: 50px;
    background-color: #f8f9fa;
  }
  
  .not-found-container h1 {
font-size: 3rem;
    color: #d9534f;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  .not-found-button {
    display: inline-block;
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .not-found-button:hover {
    background-color: #0056b3;
  }
  