.navbar {
  width: 80%;
  margin: 30px auto;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.navbar-logo img {
  width: 140px;
  height: auto;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-item {
  font-family: "Inter", sans-serif;
  font-size: 16px; /* Increased font size */
  color: #252525;
  margin: 0 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.navbar-item:hover,
.navbar-item.active {
  color: #6100ff; /* Highlight color */
}

.navbar-right {
  display: flex;
  align-items: center;
}

.nav-book-demo {
  margin-left: 20px;
  padding: 12px 18px;
  font-family: "Inter", sans-serif;
  font-size: 15px; /* Larger font size */
  color: #f7f7f7;
  background-color: #6100ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-book-demo:hover {
  background-color: #4a00cc; /* Darker on hover */
}

.li-nav-link {
  text-decoration: none;
}

.mobile-menu-icon {
  display: none;
  font-size: 18px;
  padding: 10px 15px;
  background-color: #313131;
  color: #f7f7f7;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-menu-icon:hover {
  background-color: #212121;
}

.navbar-links.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 15px 0;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .navbar {
    width: 90%; /* Increase width for better spacing on tablets */
    padding: 12px 20px;
  }

  .navbar-links {
    display: none;
    width: 100%;
    margin-top: 30px;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-item {
    width: 100%;
    margin: 10px 0;
    text-align: left;
    padding: 10px 20px;
    font-size: 18px; /* Adjusted font size for tablet */
    color: #252525;
  }

  .navbar-right {
    justify-content: flex-end;
  }

  .mobile-menu-icon {
    display: block;
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .navbar {
    width: 95%;
    padding: 12px 20px;
  }

  .navbar-links {
    display: none;
    width: 100%;
    margin-top: 30px;
  }
  .navbar-links.active{
    margin-top:30px;
    
    width: 96%;
    margin-left: 13px;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-item {
    width: 100%;
    margin: 10px 0;
    text-align: left;
    padding: 10px 20px;
    font-size: 18px; /* Larger font for tablet */
    color: #252525;
  }

  .navbar-right {
    justify-content: flex-end;
  }

  .mobile-menu-icon {
    display: block;
    margin-left: 10px;
  }
}

@media (max-width: 480px) {
  .navbar-logo img {
    width: 120px;
  }

  .navbar {
    margin-top: 7px;
    width:96%;
  }

  .nav-book-demo {
    margin-left: 10px;
    padding: 8px 12px;
  }
  .navbar-links.active{
    width: 96%;
    margin-left: 6px;
  }
}

@media (max-width: 300px) {
  .navbar {
    padding: 8px;
    margin-top: 5px;
  }

  .navbar-links.active {
    top: 50px;
  }

  .navbar-item {
    padding: 8px 10px;
  }

  .nav-book-demo {
    padding: 6px 10px;
  }

  .mobile-menu-icon {
    font-size: 22px;
  }
}
