.pricing-section-container {
  width: 100%;
  padding: 2.2rem;
  margin: 0 auto;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.pricing-header {
  margin-bottom: 3rem;
}

.pricing-heading {
  font-size: 2.7rem;
  color: #252525;
  font-weight: 700;
}

.pricing-subheading {
  font-size: 1.7rem;
  margin: 1rem 0;
  color: #595959;
}

.pricing-description {
  font-size: 1.2rem;
  color: #777777;
  margin-bottom: 2rem;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.pricing-card {
  background: #f9f9f9;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.25rem;
  color: #252525;
  margin-bottom: 1rem;
  font-weight: 600;
}

.card-price {
  font-size: 1.5rem;
  color: #595959;
  margin-bottom: 1rem;
}

.price-highlight {
  color: #5a5afc;
  font-weight: bold;
}

.card-description {
  font-size: 1rem;
  color: #777777;
  margin-bottom: 1.5rem;
}

.card-features {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.feature-item {
  font-size: 0.9rem;
  color: #595959;
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.75rem;
}

.feature-item::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: #5a5afc;
  font-weight: bold;
}

.card-button {
  display: inline-block;
  background-color: #5a5afc;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.card-button:hover {
  background-color: #4a4aec;
  transform: scale(1.05);
}

.pricing-footer {
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #777777;
  text-align: center;
}

.pricing-footer .pricing-note {
  margin: 0 auto;
  max-width: 600px;
}

@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-heading {
    font-size: 2rem;
  }

  .pricing-subheading {
    font-size: 1.25rem;
  }

  .pricing-card {
    width: 90%;
  }
}
