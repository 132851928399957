/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  max-width: 1250px;
  position: relative;
  top: 50px;
}

.heading {
  text-align: center;
  width: 94%;
}

.heading h1 {
  font-size: 58px;
  font-family: "Fira sans", sans-serif;
  text-align: center;
  padding-bottom: 15px;
  color: #252525;
}

.heading .heading-p-1 {
  font-family: "Inter", sans-serif;
  font-size:18px;
  line-height: 30px;
  text-align: center;
  color: #595959;
  padding-bottom: 15px;
}

.heading button {
  color: #f7f7f7;
  background-color: #6100ff;
  height: 50px;
  font-family: "Inter", sans-serif;
  border-radius: 9px;
  padding: 8px 16px;
  margin: 0px 15px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.heading button:hover {
  background-color: #6100ff;
}

/* AR Section Styles */
.AR {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.AR .ar-image {
  width: 100%;
  object-fit: contain;
  max-width: 1000px;
  border-radius: 8px;
  height: auto;
  margin-bottom: 20px;
}

.cards {
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
}

.cards .ar-p {
  line-height: 30px;
  padding-bottom: 15px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #595959;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .heading h1 {
    font-size: 60px;
  }

  .heading .heading-p-1 {
    font-size: 16px;
  }

  .container {
    padding: 40px;
  }

  .AR .ar-image {
    max-width: 1000px;
  }
}

@media only screen and (max-width: 768px) {
  .heading h1 {
    font-size: 48px;
  }

  .heading .heading-p-1  {
    font-size: 14px;
  }

  .container {
    width: 90%;
    padding: 30px;
  }

  .AR .ar-image {
    max-width: 800px;
  }

  .heading button {
    font-size: 16px;
    height: 45px;
  }
}

@media only screen and (max-width: 480px) {
  .heading h1 {
    font-size: 36px;
  }

  .heading .heading-p-1  {
    font-size: 12px;
  }

  .container {
    width: 95%;
    padding: 20px;
  }

  .AR .ar-image {
    max-width: 600px;
  }

  .heading button {
    font-size: 14px;
    height: 40px;
  }
}

/* General Styles */
.benefits {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 10px;
  width: 100%;
}

.benefit-1,
.benefit-2,
.benefit-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.text-content {
  width: 50%;
}

.text-content h3,
.text-content h3 {
  font-family: "Fira Sans", sans-serif;
  color: #252525;
  margin-bottom: 15px;
}

.text-content h3 {
  font-size: 32px;
}

.text-content p {
  font-family: "Inter", sans-serif;
  color: #595959;
  font-size: 18px;
  line-height: 1.6;
}

.ar-image-container-1,
.ar-image-1,
img {
  width: 50%;
  height: auto;
  object-fit: contain;
}

.benefit-1 img,
.benefit-2 img,
.benefit-3 img {
  width: 100%;
  border-radius: 8px;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .benefits {
    padding: 40px;
    gap: 30px;
  }

  .text-content h3 {
    font-size: 32px;
  }

  .text-content p {
    font-size: 18px;
  }

  .benefit-1,
  .benefit-2,
  .benefit-3 {
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .benefits {
    padding: 30px;
  }

  .benefit-1,
  .benefit-2,
  .benefit-3 {
    flex-direction: column;
    text-align: center;
  }

  .text-content {
    width: 100%;
  }

  .ar-image-container-1,
  .ar-image-1,
  img {
    width: 100%;
  }

  .text-content h3 {
    font-size: 28px;
  }



  .text-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .benefits {
    padding: 20px;
  }

  .text-content h3 {
    font-size: 20px;
  }

  .text-content p {
    font-size: 12px;
  }
}

/* General Styles */
.animations {
  text-align: center;
  padding: 60px;
}

.animations h4 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #252525;
  margin-bottom: 20px;
}

.animations h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  color: #252525;
  font-weight: 500;
  margin-bottom: 20px;
}

.animations .ani-p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
  margin-bottom: 30px;
}

.animations button {
background-color: white;
  font-family: "Inter", sans-serif;
  color: #252525;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #6100ff;
  cursor: pointer;
}

.animations button:hover {
  background-color: #6100ff;
}

/* Video Section */

.video-section {
  color: white;
  width: 100%;
  height: auto;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  overflow: hidden;
  border-radius: 15px;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); */
}

.video {
  color: white;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: inherit;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .animations h2 {
    font-size: 48px;
    width: 100%;
  }

  .animations .ani-p  {
    font-size: 16px;
  }

  .video-section {
    padding: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .animations {
    padding: 40px;
  }

  .animations h2 {
    font-size: 32px;
  }

  .animations .ani-p  {
    font-size: 14px;
  }

  .animations button {
    font-size: 16px;
    padding: 12px 24px;
  }

  .video-section {
    padding: 20px;
  }

  .video-section video {
    max-width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .animations {
    padding: 30px;
  }

  .animations h2 {
    font-size: 20px;
  }


  .animations .ani-p  {
    font-size: 12px;
  }

  .animations button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .video-section {
    padding: 10px;
  }

  .video-section video {
    box-shadow: none;
    width: 100%;
    max-width: 100%;
  }
}

/* General Styles */
.Augmented-reality-sec {
  margin-top: 30px;
  text-align: center;
  padding: 60px;
}

.Augmented-reality-sec h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #252525;
  margin-bottom: 60px;
}

.Augmented-reality {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.aug-ex {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  max-width: 30%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.aug-ex img {
  width:60%;
  height: auto;
  margin-bottom: 20px;
  object-fit: contain;
  border-radius: 10px;
}

.aug-ex h3 {
  font-family: "Fira Sans", sans-serif;
  font-size: 32px;
  color: #252525;
  margin-bottom: 15px;
}

.aug-ex .aug-para {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
  margin-bottom: 20px;
}

.aug-ex button {
  font-family: "Inter", sans-serif;
  background-color: #6100ff;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.aug-ex button:hover {
  background-color: #6100ff;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .Augmented-reality-sec h2 {
    font-size: 40px;
  }

  .aug-ex h3 {
    font-size: 22px;
  }

  .aug-ex .aug-para{
    font-size: 14px;
  }

  .Augmented-reality {
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .Augmented-reality {
    flex-direction: column;
    align-items: center;
  }

  .aug-ex {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .Augmented-reality-sec {
    padding: 40px;
  }

  .Augmented-reality-sec h2 {
    font-size: 36px;
  }

  .aug-ex h3 {
    font-size: 20px;
  }

  .aug-ex .aug-para{
    font-size: 14px;
  }

  .aug-ex button {
    font-size: 16px;
    padding: 10px 16px;
  }
}

@media only screen and (max-width: 480px) {
  .Augmented-reality-sec {
    padding: 30px;
  }

  .Augmented-reality-sec h2 {
    font-size: 20px;
  }

  .aug-ex {
    max-width: 100%;
  }

  .aug-ex h3 {
    font-size: 18px;
  }

  .aug-ex .aug-para {
    font-size: 12px;
  }

  .aug-ex button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

/* General Styles */
.tips-desc {
  text-align: center;
  width: 100%;
  padding: 60px;
}

.tips-desc h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #252525;
  margin-bottom: 40px;
}

.tips {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.tip {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  flex: 1 1 30%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tip h4 {
  font-family: "Fira Sans", sans-serif;
  font-size: 22px;
  color: #252525;
  margin-bottom: 15px;
}

.tip p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .tips-desc h2 {
    font-size: 40px;
  }

  .tip h4 {
    font-size: 22px;
  }

  .tip p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .tips {
    flex-direction: column;
    align-items: center;
  }

  .tip {
    max-width: 80%; /* Make tips wider on medium screens */
    margin-bottom: 20px;
  }

  .tips-desc {
    padding: 40px;
  }

  .tips-desc h2 {
    font-size: 36px;
  }

  .tip h4 {
    font-size: 20px;
  }

  .tip p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .tips-desc {
    padding: 30px;
  }

  .tips-desc h2 {
    font-size: 20px;
  }

  .tip {
    max-width: 100%;
  }

  .tip h4 {
    font-size: 18px;
  }

  .tip p {
    font-size: 12px;
  }
}

/* General Styles */
.businesses {
  padding: 60px;
  width: 100%;
}
.businesses h2{
  text-align: center;
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  color: #252525;
  margin-bottom: 60px;
  
}

.businesses .bus-1,
.businesses .bus-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 20px;
}

.text-content {
  flex: 1;
  padding: 20px;
}

.text-content h3 {
  font-family: "Fira Sans", sans-serif;
  font-size: 32px;
  color: #252525;
  margin-bottom: 20px;
}

.text-content p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
  margin-bottom: 20px;
}

.text-content button {
  font-family: "Inter", sans-serif;
  padding: 12px 20px;
  border: 1px solid #6100ff;
  border-radius: 8px;
  cursor: pointer;
}

.text-content button:hover {
  background-color: #6100ff;
}

.businesses img {
  max-width: 500px;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .text-content h3 {
    font-size: 32px;
  }

  .text-content p {
    font-size: 16px;
  }

  .text-content button {
    font-size: 16px;
    padding: 10px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .businesses .bus-1,
  .businesses .bus-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-content {
    padding: 0;
  }

  .businesses img {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .text-content h3 {
    font-size: 28px;
  }

  .text-content p {
    font-size: 14px;
  }

  .text-content button {
    font-size: 16px;
    padding: 10px 16px;
  }
}

@media only screen and (max-width: 480px) {
  .businesses {
    padding: 30px;
  }
  .businesses h2{
    font-size: 20px;
  }

  .text-content h3 {
    font-size: 20px;
  }

  .text-content p {
    font-size: 14px;
  }

  .text-content button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .businesses img {
    max-width: 100%;
  }
}

/* General Styles */
.e-commerce {
  padding: 60px;
  text-align: center;
  width: 100%;
}

.e-commerce h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #252525;
  margin-bottom: 40px;
}

.e-commerce img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 40px;
}

.ecom-tips {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.ecom-tip {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  flex: 1 1 30%; /* 30% width for larger screens */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ecom-tip h4 {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  color: #252525;
  margin-bottom: 15px;
}

.ecom-tip p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .e-commerce h2 {
    font-size: 40px;
  }

  .ecom-tip h4 {
    font-size: 22px;
  }

  .ecom-tip p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .ecom-tips {
    flex-direction: column;
    align-items: center;
  }

  .ecom-tip {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .e-commerce {
    padding: 40px;
  }

  .e-commerce h2 {
    font-size: 23px;
  }

  .ecom-tip h4 {
    font-size: 15px;
  }

  .ecom-tip p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .e-commerce {
    padding: 30px;
  }

  .e-commerce h2 {
    font-size: 20px;
  }

  .ecom-tip {
    max-width: 100%;
  }

  .ecom-tip h4 {
    font-size: 18px;
  }

  .ecom-tip p {
    font-size: 12px;
  }
}

/* General Styles */
.media-section {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.media-section h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #252525;
  margin-bottom: 40px;
}


.threed-video-containers {
  color: white;
  width: 100%;
  height: 650px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.threed-videos {
  width: 100%;
  height: 650px;
  object-fit: cover;
  border-radius: inherit;
}

@media (max-width: 768px) {
  .threed-video-containers {
    width: 100%;
    margin-top: 5%;
    /* border: 2px solid blue; */
  }
}
@media (max-width: 480px) {
 
  .vimeo-3{

    height: 200px;
    /* border: 2px solid red; */
   
  }
  
}




@media only screen and (max-width: 768px) {
  .media-section h2 {
    font-size: 36px;
  }
  .vimeo-3{

    height: 230px;
    /* border: 2px solid red; */
   
  }
  

 
  
}

@media only screen and (max-width: 480px) {
  .media-section h2 {
    font-size: 20px;
  }

  .media-section {
    /* padding: 30px; */
  }
  /* .vimeo-3{
    width: 90%;
    height: 230px;
  } */
  
}

/* General Styles */
.last {
  margin-bottom: 30px;
  padding: 60px;
  text-align: center;
}

.last h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #252525;
  margin-bottom: 40px;
}

.last h4 {
  font-family: "Fira Sans", sans-serif;
  font-size: 22px;
  color: #252525;
  margin-bottom: 40px;
}

.devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.devices p{
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #595959;
  /* margin-bottom: 40px; */
}
.devices > div {
  flex: 1 1 250px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .last h1 {
    font-size: 40px;
  }

  .last {
    padding: 50px;
  }
  .devices p{
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .last h2 {
    font-size: 20px;
  }

  .last {
    padding: 40px;
  }
  .devices p{
    font-size: 12px;
  }

  .devices {
    flex-direction: column;
    align-items: center;
  }

  .devices > div {
    max-width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .last h2 {
    font-size: 20px;
  }
  .devices p{
    font-size: 12px;
  }

  .last {
    padding: 30px;
  }
  .devices > div {
    
    max-width: 90%;
   

  }
}
