/* Loader container to center the loader in the middle of the screen */
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f9f9f9;
  }
  
  /* Loader image */
  .loader {
    width: 80px; /* Default size for larger screens */
    height: 100px;
    margin-bottom: 20px;
  }
  
  /* Loader text */
  .load-head {
    font-family: Arial, sans-serif;
    font-size: 1.5rem;
    color:blue;
  }
  
  .sp-1, .sp-2, .sp-3 {
    animation: blink 1.5s infinite ease-in-out;
  }
  
  .sp-1 {
    animation-delay: 0.2s;
  }
  
  .sp-2 {
    animation-delay: 0.4s;
  }
  
  .sp-3 {
    animation-delay: 0.6s;
  }
  
  /* Blinking animation for dots */
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  /* Responsive design for tablet and mobile screens */
  @media screen and (max-width: 1024px) {
    .loader {
      width: 80px;  /* Slightly smaller loader for tablet view */
      height: 80px;
    }
  
    .load-head {
      font-size: 1.2rem;  /* Adjust font size for tablets */
    }
  }
  
  @media screen and (max-width: 768px) {
    .loader {
      width: 60px; /* Smaller size for mobile view */
      height: 60px;
    }
  
    .load-head {
      font-size: 1rem;  /* Adjust font size for mobile screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .loader {
      width: 50px; /* Further reduction for smaller mobile screens */
      height: 50px;
    }
  
    .load-head {
      font-size: 0.9rem;  /* Smaller font size for small mobile screens */
    }
  }
  