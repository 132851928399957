@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');


.layer-1-container {
  padding: 10px;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  top: 20px; 
}

.layer-1-head {
  font-size: 30px;
  text-align: center;
  padding-top: 10px; 
  font-family: "Fira Sans", sans-serif;
}

.layer-1-heading-1 {
  color: #0f0f0f;
  font-size: 58px;
  margin: 0;
}

.layer-1-para {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
}

.layer-1-paragraph {
  padding-top: 10px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 80px;
  font-style: normal;
  color: #595959;
  font-size: 18px;
  margin: 0;
}

.layer-1-demo {
  text-align: center;
 
}

.button {
  background-color: #6d00ff;
  color: white;
  font-family: 'Inter', sans-serif;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .layer-1-container {
    top: -30px; 
  }
  /* .layer-1-container {
    margin-top: 180px;
  } */

  .layer-1-heading-1 {
    font-size: 1.5em;
  }

  .layer-1-paragraph {
    font-size: 1em;
  }

  .layer-1-heading-2 {
    font-size: 1.2em;
  }

  .button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .layer-1-container {
    top: -20px; 
  }

  .layer-1-heading-1 {
    font-size: 1.2em;
  }

  .layer-1-paragraph {
    font-size: 0.9em;
  }

  .layer-1-heading-2 {
    font-size: 1em;
  }

  .button {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}

/* video  css */
.video-container {
  color: white;
  width: 80%;
  height: auto; 
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  overflow: hidden; 
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); 
}


.video {
  color: white;
  width: 100%;
  height: 650px;
  object-fit: cover; 
  border-radius: inherit; 
  -webkit-transform: translate3d(0, 0, 0); 
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
  .video-container {
    /* border: 2px solid red; */
    width: 90%;
    margin-top: 5%;
  }
}
.layer-2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}
.vimeo-1{
  /* border: 2px solid red; */
}
.layer-2-small-head {
  margin-top: 15px;
}

.layer-2-small-heading {
  font-size: 17px;
  font-family: 'Inter' sans-serif;
  text-transform: uppercase;
  margin: 0px 0px 40px;
  color:#252525; 
}

.layer-2-head {
  margin-bottom: 30px;
  text-align: center;
}

.layer-2-heading {

  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  font-size: 48px;
  color: #252525; 
}

.layer-2-para {
  margin: 0;
  padding: 0; 
  width: 100%; 
  height: auto;
  box-sizing: border-box;
}

.layer-2-paragraph {
  margin: 0;
  font-size: 18px;
  line-height: 1.6;
  font-family: "Fira Sans", sans-serif;
  color: #252525;
  word-wrap: break-word; 
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .layer-2-paragraph {
    font-size: 18px; 
  }
}

@media (max-width: 768px) {
  .layer-2-paragraph {
    font-size: 16px; 
  }
  .vimeo-1{
    /* border: 2px solid red;
    height: 330px; */
  }
}

@media (max-width: 480px) {
  .layer-2-paragraph {
    font-size: 14px; 
  }
  .vimeo-1{
    /* border: 2px solid black; */
    height: 230px;
  }
}

@media (max-width: 768px) {
  .layer-2-heading {
    font-size: 1.8rem;
  }
  .layer-2-paragraph {
    font-size: 0.9rem;
  }
}


.features-section {
  text-align: center;
  padding: 50px 20px;
}

.features-heading {
  font-size: 48px;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 40px;
  padding-top: 40px;
  font-weight: 500;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  gap: 30px;
}

/* Individual Feature Section Styling */
.feature-collaboration,
.feature-security,
.feature-organization {
  flex: 1;
  min-width: 280px;
  max-width: 400px;
  padding: 20px;
  text-align: left;
}

.feature-collaboration .feature-title,
.feature-security .feature-title,
.feature-organization .feature-title {
  font-size: 18px;
  color: #252525;
  font-family: 'Inter', sans-serif;
  margin-bottom: 15px;
}

.feature-collaboration .feature-description,
.feature-security .feature-description,
.feature-organization .feature-description {
  color: #595959;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
}

/* CTA Button Styling */
.cta-button-container {
  margin-top: 40px;
}


.button {
  background-color: #6d00ff;
  color: white;
  font-family: 'Inter', sans-serif;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.button:hover {
  background-color: #5400cc;
}

.button:focus {
  outline: 2px solid #5400cc;
}
/* Responsive Styles */

/* Mobile Devices (up to 300px) */
@media (max-width: 300px) {
  .features-heading {
    font-size: 1.25rem;
    padding-top: 20px;
  }

  .features-container {
    flex-direction: column;
    width: 95%;
    gap: 15px;
  }

  .feature-collaboration,
  .feature-security,
  .feature-organization {
    width: 100%;
    padding: 10px;
  }

  .feature-collaboration .feature-title,
  .feature-security .feature-title,
  .feature-organization .feature-title {
    font-size: 16px;
  }

  .feature-collaboration .feature-description,
  .feature-security .feature-description,
  .feature-organization .feature-description {
    font-size: 14px;
  }

  .cta-button {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
  }
}

/* Extra Small Devices (301px to 575px) */
@media (min-width: 301px) and (max-width: 575px) {
  .features-heading {
    font-size: 1.5rem;
    padding-top: 30px;
  }

  .features-container {
    flex-direction: column;
    width: 90%;
  }

  .feature-collaboration,
  .feature-security,
  .feature-organization {
    width: 100%;
  }

  .cta-button {
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
  }
}

/* Small Devices (576px to 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .features-heading {
    font-size: 1.75rem;
  }

  .features-container {
    flex-direction: column;
    align-items: center;
  }

  .feature-collaboration,
  .feature-security,
  .feature-organization {
    width: 80%;
  }

  .cta-button {
    width: 85%;
    padding: 14px 25px;
  }
}

/* Medium Devices (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .features-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .feature-collaboration,
  .feature-security,
  .feature-organization {
    max-width: 350px;
  }

  .cta-button {
    width: 70%;
    padding: 15px 30px;
  }
}

/* Large Devices (1025px and up) */
@media (min-width: 1025px) {
  .features-container {
    justify-content: space-evenly;
  }

  .feature-collaboration,
  .feature-security,
  .feature-organization {
    max-width: 400px;
  }

  .cta-button {
    width: auto;
    padding: 15px 30px;
  }
}



/* ShowcaseSection.css */

.showcase-section {
  text-align: center;
  padding: 50px 20px;
}

.showcase-subheading {
  font-size: 14px;
  color: #252525;
  margin-bottom: 48px;
 
  font-family: 'Inter'  sans-serif;
}

.showcase-heading {
  font-weight: 500;
  font-size: 48px;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 20px;
  color: #252525;
}

.showcase-description {
  font-family: 'Inter'  sans-serif;
  font-size: 18px;
  color: #595959;
  margin-bottom: 40px;
  line-height: 1.6;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.showcase-button-container {
  margin-top: 20px;
}

.showcase-button {
  background-color: white;
  color: #6d00ff; 
  border: 2px solid #6d00ff;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.showcase-button:hover {
  background-color: #6d00ff;
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .showcase-heading {
    font-size: 2rem;
  }

  .showcase-description {
   
    width: 80%;
    font-size: 1rem;
  }

  .showcase-button {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .showcase-heading {
    font-size: 1.75rem;
  }

  .showcase-description {
    font-size: 0.95rem;
  }

  .showcase-button {
    padding: 10px 20px;
    font-size: 0.95rem;
  }
}

/* .vc-1{
 padding-top: 130px;
} */
/* General Styling for All Devices */
.copy-section{
  margin-top: 50px;
  text-align: center;
  padding: 50px 20px;
}

.copy-subheading {
  font-size: 14px;
  text-transform: uppercase;
  color: #252525;
  margin-bottom: 48px;
  font-family: 'Inter', sans-serif;
}

.copy-heading {
  font-size: 48px;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 20px;
  font-weight: 500;
  color: #252525;
}

.copy-description,
.copy-head-5 {
  
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #595959;
  margin-bottom: 30px;
  line-height: 1.6;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Copy Button */
.copy-button-container {
  margin-top: 40px;
}

.copy-button {
  background-color: #6d00ff;
  font-family: 'Inter', sans-serif;
  color: white;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #5400cc;
}

.copy-link-success {
  color: green;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Responsive Styling */

/* For small devices (mobiles, tablets) */
@media (max-width: 768px) {
  .copy-heading {
    font-size: 28px; /* Smaller heading for mobile */
  }

  .copy-subheading {
    font-size: 14px; /* Adjusted subheading */
  }

  .copy-description,
  .copy-head-5 {
    font-size: 16px;
    width: 90%; /* Full width for better readability */
  }

  .copy-button {
    padding: 12px 20px;
    font-size: 0.9rem; /* Smaller button on mobile */
  }
}

/* For very small devices (phones) */
@media (max-width: 480px) {
  .copy-heading {
    font-size: 24px;
  }

  .copy-subheading {
    font-size: 12px;
  }

  .copy-description,
  .copy-head-5 {
    font-size: 14px;
    width: 95%;
  }

  .copy-button {
    padding: 10px 15px;
    font-size: 0.8rem;
  }
}

/* For larger devices (desktops) */
@media (min-width: 1024px) {
  .copy-heading {
    font-size: 48px;
  }

  .copy-subheading {
    font-size: 18px;
  }

  .copy-description,
  .copy-head-5 {
    font-size: 20px;
    width: 60%;
  }

  .copy-button {
    padding: 18px 40px;
    font-size: 1.1rem;
  }
}

.iframe-container {
  width: 85%;
  height: 50%;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Similar to video shadow */
}

.iframe {
  width: 100%;
  height: 650px;
  object-fit: cover; 
  border-radius: inherit; /* Matches parent container */
  -webkit-transform: translate3d(0, 0, 0); 
  -webkit-overflow-scrolling: touch;
  border: none; /* Remove iframe border */
}

@media (max-width: 768px) {
  .iframe-container {
    display:none;
    width: 100%;
    margin-top: 5%;
  }

  .iframe {
    height: 300px; /* Adjust height for smaller screens */
  }
}

/* Base styling for all screen sizes */
.industries-container {
  margin-top: 50px;
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.industries-heading {
  text-align: center;
  color: #252525;
  font-size: 48px;
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 50px;
}

.industries-cards {
  padding-left: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
}

.card {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  width: 100%; /* Set width to 100% for responsiveness */
  max-width: 300px; /* Max width for cards */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 200px; 
  object-fit: cover;
}

.card-info {
  padding: 20px;
  text-align: center;
}

.industry-label {
  font-family: 'Inter sans-serif';
  font-size: 15px;
  color: #949494;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.view-demo-btn {
  background-color: transparent;
  border: 2px solid #6a00ff;
  color: #6a00ff;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.view-demo-btn:hover {
  background-color: #6a00ff;
  color: #fff;
}

/* Responsive styles */

/* Large Desktop screens (1200px and above) */
@media (min-width: 1200px) {
  .industries-heading {
    font-size: 48px;
  }

  .industries-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Tablet screens (768px to 1199px) */
@media (max-width: 1199px) and (min-width: 768px) {
  .industries-heading {
    font-size: 2.2rem;
  }

  .industries-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    justify-content: center; /* Center the cards */
  }
}

/* Mobile screens (767px and below) */
@media (max-width: 767px) {
  .industries-heading {
    font-size: 1.8rem;
  }

  .industries-cards {
    grid-template-columns: repeat(1, 1fr); /* Stack cards in one column for mobile */
    padding-left: 10px; /* Add left padding */
    padding-right: 10px; /* Add right padding */
  }

  .card {
    max-width: 300px; /* Limit the card width on mobile */
    margin: 0 auto; /* Center the card */
  }
}
