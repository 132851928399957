.faq-container {
  margin-top: 100px;
  width: 80%;
  margin: 0 auto;
  padding: 30px;
  /* background-color: white; */
}

.faq-title {
  
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;
  color: #252525;
}

.faq-item {
  width: 100%;
  margin: 0 auto 20px;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-question {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #252525;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.faq-plus-minus {
  font-size: 1.5rem;
  color: #6d00ff;
}

.faq-answer .p-data {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #595959;
  line-height: 1.6;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-left: 3px solid #6d00ff;
  border-radius: 5px;
  margin-top: 10px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-title {
    font-size: 32px;
  }
  .faq-question {
    font-size: 1.1rem;
  }
  .faq-plus-minus {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .faq-container {
    
    padding: 20px;
  }
  .faq-title {
    font-size: 1.7rem;
   max-width:400px;
  }
  .faq-question {
    font-size: 1rem;
  }
}
